export const setToLS = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLS = (key: string) => {
  const value = window.localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }

  return null;
};

export const setCookie = (name: string, value: string, days = 7): void => {
  const expires = new Date(
    Date.now() + days * 24 * 60 * 60 * 1000
  ).toUTCString();
  document.cookie = `${name}=${value}; path=/; domain=.traderion.com; expires=${expires}; Secure; SameSite=Strict`;
};

export const getCookie = (name: string): string | null => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

export const deleteCookie = (name: string): void => {
  document.cookie = `${name}=; path=/; domain=.traderion.com; expires=Thu, 01 Jan 1970 00:00:00 GMT; Secure; SameSite=Strict`;
};
