import CreateClassModal from "components/AdminCustomers/Classes/Modals/CreateClassModal";
import { Button, Input, Modal } from "antd";
import CustomTable from "components/reusable/CustomTable";
import {
  Column,
  Row,
  DataContainer,
} from "components/reusable/GenericStyledComponents";
import StyledText from "components/reusable/StyledText";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { adminService } from "services";
import styled from "styled-components";
import { Customer, DataRow } from "types";
import settings from "settings";
// import AddNewClass from './AddNewClass';
// import EditClassModal from './Modals/EditClassModal';

const AdminCustomersPanel: React.FC<{ customers: Customer[] }> = ({
  customers,
}) => {
  const [showNewCustomerModal, setShowNewCustomerModal] =
    useState<boolean>(false);
  const [customerName, setCustomerName] = useState<string>("");

  if (!customers || !customers.length) return null;
  return (
    <Column
      style={{
        height: "100%",
        overflow: "auto",
        alignItems: "center",
      }}
    >
      {/* <CreateClassModal
                isShown={newClassModalShown}
                closeModal={() => { setNewClassModalShown(false) }}
                cls={clsPayload}
                setCls={setClsPayload}
            />
            <EditClassModal
                isShown={editClassModalShown}
                closeModal={() => { setEditClassModalShown(false) }}
                cls={editedClass}
            /> */}
      <StyledText fontSize={20} fontWeight={600} fontFamily="IBMPlexSans">
        Customers overview
      </StyledText>
      <DataContainer>
        <CustomTable
          header={["name"]}
          data={[
            ...customers
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(
                (customer): DataRow => ({
                  data: [
                    () => (
                      <StyledText
                        fontSize={14}
                        fontWeight={500}
                        color="#FFFFFF90"
                        fontFamily="IBMPlexSans"
                      >
                        {customer.name}
                      </StyledText>
                    ),
                    () => (
                      <Row style={{ gap: ".5rem" }}>
                        <Link to={`/admin/customer/${customer.id}`}>
                          <Button>
                            <StyledText
                              fontSize={14}
                              fontWeight={400}
                              color="#FFFFFF70"
                              fontFamily="IBMPlexSans"
                            >
                              Go to customer page
                            </StyledText>
                          </Button>
                        </Link>
                      </Row>
                    ),
                  ],
                })
              ),
            // {
            //     data: [
            //         () => (
            //             <AddNewClass
            //                 // setSelectedScenario={() => {
            //                 //     // setSelectedAdminScenario(empty_scenario_defaults);
            //                 // }}
            //                 setIsShown={() => {
            //                     setNewClassModalShown(true);
            //                 }}
            //             />
            //         ),
            //     ],
            // },
          ]}
        />
      </DataContainer>
      <Modal
        open={showNewCustomerModal}
        title="Add new customer"
        onOk={async () => {
          await adminService.createCustomer(customerName);
          window.location.reload();
        }}
        okText="Submit"
        onCancel={() => setShowNewCustomerModal(false)}
        destroyOnClose
        bodyStyle={{ padding: "2rem 1rem", width: "auto" }}
      >
        <Input
          placeholder="Customer name"
          value={customerName}
          onChange={(e) => {
            setCustomerName(e.target.value);
          }}
        />
      </Modal>
      <Button
        onClick={() => {
          setShowNewCustomerModal(true);
        }}
      >
        +
      </Button>
    </Column>
  );
};

export default AdminCustomersPanel;
