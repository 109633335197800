import { AxiosInstance } from "axios";
import { LoginDTO, LoginResponse, RegisterDTO, RegisterResponse, ModifiedUserType } from "types";
import { setCookie, getCookie, deleteCookie } from "utils";

export default class AuthService {
  private httpClient: AxiosInstance;

  constructor(client: AxiosInstance) {
    this.httpClient = client;
  }

  async login(data: LoginDTO): Promise<LoginResponse> {
    const response = await this.httpClient.post<LoginResponse>("/api/o/token", data);

    // Set token in cookies
    setCookie("options-token", response.data.access);
    setCookie("options-refresh-token", response.data.refresh);

    return response.data;
  }

  async register(data: RegisterDTO): Promise<RegisterResponse> {
    const response = await this.httpClient.post<RegisterResponse>("/api/o/register", data);
    return response.data;
  }

  async editUser(data: ModifiedUserType): Promise<any> {
    const response = await this.httpClient.put<any>(`/api/user/${data.userId}`, data);
    return response.data;
  }

  async logout() {
    deleteCookie("options-token");
    deleteCookie("options-refresh-token");
    return true;
  }
}
