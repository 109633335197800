import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { RootState } from "store/rootReducer";
import SessionsList from "../components/Lobby/SessionsList";
import Header from "../components/reusable/Header";
import ServerStatusProvider from "contexts/ServerStatusProvider";
import ServerStatusModal from "components/Modal/ServerStatusModal";
import LogInView from "../components/Lobby/LogInView";
import useModal from "../hooks/useModal";
import settings from "settings";
import { Redirect } from "react-router";

const Lobby: React.FC = () => {
  const { isAuthenticated } = useSelector((state: RootState) => state.user);
  const [StatusModal, showModal] = useModal(ServerStatusModal);

  if (!isAuthenticated) {
    if (settings.mfa_redirect) {
      window.location.href = "https://portal.traderion.com/options";
      return null;
    }
    return <LogInView />;
  }

  return (
    <ServerStatusProvider>
      <Container>
        <Header showServerStatus={showModal} />

        <Wrapper>
          <SessionsList />
        </Wrapper>

        {StatusModal}
      </Container>
    </ServerStatusProvider>
  );
};

const Container = styled.div`
  min-height: 100vh;
`;

const Wrapper = styled.div`
  display: flex;
  padding: 3rem 6rem;
  background-image: linear-gradient(
      to bottom,
      #000 0%,
      rgba(0, 0, 0, 0.1) 5rem,
      transparent 100%
    ),
    linear-gradient(to bottom, #222831, #222831 60%, #000 100%);
`;

export default Lobby;
