import { useEffect } from "react";
import { useAppDispatch } from "store/store";
import { userSuccessAuth } from "store/user/reducers";
import { getCookie } from "utils";

export const useAuth = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const accessToken = getCookie("options-token");
    const refreshToken = getCookie("options-refresh-token");

    if (accessToken && refreshToken) {
      dispatch(
        userSuccessAuth({
          access: accessToken,
          name: "user",
          refresh: refreshToken,
        })
      );
    }
  }, []);
};
