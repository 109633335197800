import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store/rootReducer";
import settings from "settings";

interface PrivateRouteProps extends RouteProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component?: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children?: any;
}

const PrivateRoute = (props: PrivateRouteProps) => {
  const { component: Component, children, ...rest } = props;

  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        if (!isAuthenticated) {
          if (settings.mfa_redirect) {
            // Hard-redirect to portal.traderion.com
            window.location.href = "https://portal.traderion.com/options";
            return null;
          }
          // Old internal redirect to "/"
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }

        // If authenticated, render the intended component or children
        return Component ? <Component {...routeProps} /> : children;
      }}
    />
  );
};

export default PrivateRoute;
