import axios from "axios";
import { useEffect } from "react";
import AuthService from "./auth.service";
import TrainerService from "./trainer.service";
import jwtDecode from "jwt-decode";

import { accessTokenKey, refreshTokenKey } from "utils/constants";
import RoomsService from "./rooms.service";
import AdminService from "./admin";
import ReportsService from "./reports.service";
import { deleteCookie, getCookie, setCookie } from "utils";

function isTokenExpired(token: string): boolean {
  try {
    const { exp } = jwtDecode<{ exp: number }>(token);
    return Date.now() >= exp * 1000;
  } catch (e) {
    return true;
  }
}

export const useConfigureServices = (baseUrl = ""): void => {
  useEffect(() => {
    axios.defaults.baseURL = baseUrl;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.defaults.headers.post.Accept =
      "application/json, text/plain, multipart/form-data, */*";

    axios.interceptors.request.use(
      (config) => {
        const token = getCookie("options-token");
        if (token) {
          if (isTokenExpired(token)) {
            deleteCookie("options-token");
            deleteCookie("options-refresh-token");
            window.location.href = "https://portal.traderion.com/options";
            return Promise.reject("Token expired");
          }
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => {
        if (response.status === 200 && response.config.url === "/api/o/token") {
          setCookie("options-token", response.data.access);
          setCookie("options-refresh-token", response.data.refresh);
        }
        return response;
      },
      (error) => {
        const originalRequest = error.config;
        if (
          (error.response.status === 401 && !originalRequest.retry,
          originalRequest.url !== "/api/o/token") // Skip if login request
        ) {
          originalRequest.retry = true;
          return axios
            .post<{ access: string; refresh: string }>("/api/o/token/refresh", {
              refresh: getCookie("refresh-token"),
            })
            .then((res) => {
              if (res.status === 200) {
                setCookie("options-token", res.data.access);
                setCookie("options-refresh-token", res.data.refresh);
                axios.defaults.headers.common.Authorization = `Bearer ${res.data.access}`;
                return axios(originalRequest);
              }
              deleteCookie("options-token");
              deleteCookie("options-refresh-token");
              window.location.href = "https://portal.traderion.com/options";
              return Promise.reject("Error while refreshing token");
            })
            .catch((refreshError) => {
              deleteCookie("options-token");
              deleteCookie("options-refresh-token");
              window.location.href = "https://portal.traderion.com/options";
              return Promise.reject(refreshError);
            });
        }
        return Promise.reject(error);
      }
    );
  }, [baseUrl]);
};

export const authService = new AuthService(axios);
export const trainerService = new TrainerService(axios);
export const roomService = new RoomsService(axios);
export const adminService = new AdminService(axios);
export const reportsService = new ReportsService(axios);
