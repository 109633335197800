import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

import { RootState } from "store/rootReducer";
import { authService } from "services";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";
import StyledText from "components/reusable/StyledText";
import settings from "settings";

const ReportsHeader: React.FC = () => {
  const { isAuthenticated, permission, username } = useSelector(
    (state: RootState) => state.user
  );

  return (
    <Container>
      <LogoLink to="/">
        <img src="/logo.png" alt="logo" />
      </LogoLink>

      <Menu>
        {(permission === "Trainer" || permission === "Admin") && (
          <NavLink to="/trainer">Trainer Dashboard</NavLink>
        )}
        {permission === "Admin" && (
          <NavLink style={{ color: "green" }} to="/admin">
            Admin panel
          </NavLink>
        )}
      </Menu>

      {isAuthenticated ? (
        <div style={{ display: "flex", gap: "1.5rem" }}>
          <div
            style={{
              display: "flex",
              gap: ".25rem",
              fontSize: "18",
              alignItems: "center",
            }}
          >
            <UserOutlined />
            {username}
          </div>
          <LogOutButton
            onClick={async () => {
              await authService.logout();
              if (settings.mfa_redirect) {
                window.location.href = "https://portal.traderion.com/options";
              } else {
                window.location.reload();
              }
            }}
          >
            <LogoutOutlined style={{ marginRight: ".25rem" }} /> Log out
          </LogOutButton>
        </div>
      ) : (
        <NavLink to="/" className="mr-2">
          <Circle />
          <StyledText fontSize={15} fontWeight={500}>
            My account
          </StyledText>
        </NavLink>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  //border-bottom: 1px solid #000;
  box-shadow: 0 6px 15px 0 rgb(0 0 0 / 25%);
`;

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primaryText} !important;
  font-size: 15px;
  font-family: SFProText, serif;
  letter-spacing: 1.2px;

  &:hover {
    color: ${({ theme }) => theme.colors.secondaryText} !important;
  }

  & a {
    color: ${({ theme }) => theme.colors.primaryText} !important;
  }
`;

const ServerStatusButton = styled.div`
  color: ${({ theme }) => theme.colors.primaryText} !important;
  font-size: 15px;
  font-family: SFProText, serif;
  letter-spacing: 1.2px;
  display: flex;

  &:hover {
    color: ${({ theme }) => theme.colors.secondaryText} !important;
  }

  & a {
    color: ${({ theme }) => theme.colors.primaryText} !important;
  }
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  background-color: #c4c4c4;
  border-radius: 15px;
  margin-right: 14px;
`;

const LogoLink = styled(Link)`
  margin-right: 30px;
`;

const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-right: auto;
`;

const LogOutButton = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  font-size: 18px;

  &:hover,
  &:active,
  &:focus {
    color: #cca20e;
  }

  display: flex;
  align-items: center;
  gap: 5px;
`;

export default ReportsHeader;
